import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ApplyChanges from "./ApplyChanges";

const AdditionalConstructionSection = ({
  capData,
  toggleData,
  setCapData,
  currentTable,
  updateCapData,
  isUpdateCapDataLoading,
}) => {
  const [isElevatorEditable, setIsElevatorEditable] = useState(false);
  const [isHoistEditable, setIsHoistEditable] = useState(false);
  const [isSiteCordinatorEditable, setIsSiteCordinatorEditable] =
    useState(false);
  const [isSiteSafetyEditable, setIsSiteSafetyEditable] = useState(false);
  const [istempStandPipeEditable, setIsTempStandPipeEditable] = useState(false);

  useEffect(() => {
    if (capData?.zoningDistricts && capData?.zoningDistricts.length > 0) {
      if (/^R[6-9]|^R10/.test(capData?.zoningDistricts[0])) {
        setIsElevatorEditable(true);
      } else if (/^R[1-5]/.test(capData?.zoningDistricts[0])) {
        setIsElevatorEditable(false);
      }

      if (/^R[8-9]|^R10/.test(capData?.zoningDistricts[0])) {
        setIsHoistEditable(true);
      } else if (/^R[1-7]/.test(capData?.zoningDistricts[0])) {
        setIsHoistEditable(false);
      }
    }
  }, [capData?.zoningDistricts]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let validValue = value ? value.replace(/[^0-9.-]/g, "") : "0";

    if (validValue === "0" && value !== "") {
      validValue = value.replace(/[^0-9.-]/g, "");
    }
    setCapData((prevCapData) => ({
      ...prevCapData,
      [currentTable]: {
        ...prevCapData[currentTable],
        [name]: validValue,
      },
    }));
  };

  const [additionalSections, setAdditionalSections] = useState([]);

  const addSection = () => {
    const newId = additionalSections.length
      ? additionalSections[additionalSections.length - 1].id + 1
      : 1;
    setAdditionalSections([
      ...additionalSections,
      { id: newId, label: `New Section ${newId}` },
    ]);
  };

  const removeSection = (id) => {
    setAdditionalSections(
      additionalSections.filter((section) => section.id !== id)
    );
  };

  const handleInputChange = (id, field, newValue) => {
    if (field === "value") {
      newValue = newValue.replace(/[^0-9.-]/g, "");

      if (newValue === "" || isNaN(newValue)) {
        newValue = "0";
      }
    }
    setAdditionalSections(
      additionalSections.map((section) =>
        section.id === id ? { ...section, [field]: newValue } : section
      )
    );
  };

  useEffect(() => {
    let addConstruction = additionalSections.map((section) => section.value);

    setCapData((prevCapData) => ({
      ...prevCapData,
      [currentTable]: {
        ...prevCapData[currentTable],
        addConstruction: addConstruction,
      },
    }));

  }, [additionalSections, currentTable]);

  return (
    <>
      <div className="px-4 pb-3 text-gray-600">
        <div className="flex flex-row items-center my-4 justify-between">
          <label className="w-[30%] mr-2">Excavation</label>
          <div className="flex flex-row items-center justify-end w-[50%]">
            <input
              type="text"
              name="Excavation"
              className="border-[1px] px-2 rounded w-[90%] border-black"
              value={toggleData?.Excavation || "--"}
              onChange={handleChange}
            />
            <span className="w-[25%] text-right">$</span>
          </div>
        </div>

        <div className="flex flex-row items-center my-2 justify-between">
          <label className="w-[30%] mr-2">Basement</label>
          <div className="flex flex-row items-center justify-end w-[50%]">
            <input
              type="text"
              name="BFO"
              className="border-[1px] px-2 rounded w-[90%] border-black"
              value={toggleData?.BFO || "--"}
              onChange={handleChange}
            />
            <span className="w-[25%] text-right">$</span>
          </div>
        </div>

        <div className="flex flex-row items-center my-2 justify-between">
          <div className="flex flex-row items-center">
            <input
              type="checkbox"
              className="mr-2"
              checked={isElevatorEditable}
            />
            <label>
              Elevators <span className="hover:cursor-pointer">(i)</span>
            </label>
          </div>
          <div className="flex flex-row items-center justify-end w-[50%]">
            <input
              type="text"
              className={`border-[1px] px-2 rounded w-[90%] ${
                isElevatorEditable
                  ? "border-black"
                  : "border-gray-400 bg-gray-100"
              }`}
              disabled={!isElevatorEditable}
              value={toggleData?.ELE || "--"}
              name="ELE"
              onChange={handleChange}
            />
            <span className="w-[25%] text-right">$</span>
          </div>
        </div>

        <div className="flex flex-row items-center my-2 justify-between">
          <div className="flex flex-row items-center">
            <input type="checkbox" className="mr-2" checked={isHoistEditable} />
            <label>
              Hoist <span className="hover:cursor-pointer">(i)</span>
            </label>
          </div>
          <div className="flex flex-row items-center justify-end w-[50%]">
            <input
              type="text"
              className={`border-[1px] px-2 rounded w-[90%] ${
                isHoistEditable ? "border-black" : "border-gray-400 bg-gray-100"
              }`}
              disabled={!isHoistEditable}
              value={toggleData?.HOI || "--"}
              name="HOI"
              onChange={handleChange}
            />
            <span className="w-[25%] text-right">$</span>
          </div>
        </div>

        <div className="flex flex-row items-center my-2 justify-between">
          <div className="flex flex-row items-center">
            <input
              type="checkbox"
              className="mr-2"
              checked={isSiteCordinatorEditable}
              onChange={(e) => setIsSiteCordinatorEditable(e.target.checked)}
            />
            <label>Site Coordinator</label>
          </div>
          <div className="flex flex-row items-center justify-end w-[50%]">
            <input
              type="text"
              className={`border-[1px] px-2 rounded w-[90%] ${
                isSiteCordinatorEditable
                  ? "border-black"
                  : "border-gray-400 bg-gray-100"
              }`}
              disabled={!isSiteCordinatorEditable}
              value={toggleData?.SITC || "--"}
              name="SITC"
              onChange={(e) => isSiteCordinatorEditable && handleChange(e)}
            />
            <span className="w-[25%] text-right">$</span>
          </div>
        </div>

        <div className="flex flex-row items-center my-2 justify-between">
          <div className="flex flex-row items-center">
            <input
              type="checkbox"
              className="mr-2"
              checked={isSiteSafetyEditable}
              onChange={(e) => setIsSiteSafetyEditable(e.target.checked)}
            />
            <label>Site Safety</label>
          </div>
          <div className="flex flex-row items-center justify-end w-[50%]">
            <input
              type="text"
              className={`border-[1px] px-2 rounded w-[90%] ${
                isSiteSafetyEditable
                  ? "border-black"
                  : "border-gray-400 bg-gray-100"
              }`}
              disabled={!isSiteSafetyEditable}
              value={toggleData?.SAF || "--"}
              name="SAF"
              onChange={(e) => isSiteSafetyEditable && handleChange(e)}
            />
            <span className="w-[25%] text-right">$</span>
          </div>
        </div>

        <div className="flex flex-row items-center my-2 justify-between">
          <div className="flex flex-row items-center">
            <input
              type="checkbox"
              className="mr-2"
              checked={istempStandPipeEditable}
              onChange={(e) => setIsTempStandPipeEditable(e.target.checked)}
            />
            <label>Temp Standpipe</label>
          </div>
          <div className="flex flex-row items-center justify-end w-[50%]">
            <input
              type="text"
              className={`border-[1px] px-2 rounded w-[90%] ${
                istempStandPipeEditable
                  ? "border-black"
                  : "border-gray-400 bg-gray-100"
              }`}
              disabled={!istempStandPipeEditable}
              value={toggleData?.TSP || "--"}
              name="TSP"
              onChange={(e) => istempStandPipeEditable && handleChange(e)}
            />
            <span className="w-[25%] text-right">$</span>
          </div>
        </div>

        <div className="flex flex-col my-4 space-y-4">
          <div className="flex flex-row items-center justify-between">
            <label className="w-[30%] mr-2">Curtain Wall</label>
          </div>
          <div className="flex flex-row items-center justify-between">
            <label className="w-[30%] mr-2">CWL</label>
            <div className="flex flex-row items-center justify-end w-[50%]">
              <input
                type="text"
                className="border-[1px] px-2 rounded w-[90%] border-black"
                value={toggleData?.CWL || "--"}
                name="CWL"
                onChange={handleChange}
              />
              <span className="w-[25%] text-right">$/ft</span>
            </div>
          </div>

          <div className="flex flex-row items-center justify-between">
            <label className="w-[30%] mr-2">CWD</label>
            <div className="flex flex-row items-center justify-end w-[50%]">
              <input
                type="text"
                className="border-[1px] px-2 rounded w-[90%] border-black"
                value={toggleData?.CWD || "--"}
                name="CWD"
                onChange={handleChange}
              />
              <span className="w-[25%] text-right">sf</span>
            </div>
          </div>

          <div className="flex flex-row items-center justify-between">
            <label className="w-[30%] mr-2">CWT</label>
            <div className="flex flex-row items-center justify-end w-[50%]">
              <input
                disabled={true}
                value={toggleData?.CWT || "--"}
                name="CWT"
                type="text"
                className="border-[1px] px-2 rounded w-[90%] border-gray-400 bg-gray-100"
              />
              <span className="w-[25%] text-right">$</span>
            </div>
          </div>
        </div>

        {additionalSections.map((section) => (
          <div key={section.id}>
            <div className="flex items-center my-2 justify-end mt-5">
              <div className="flex flex-col w-full">
                <div className="flex space-x-2 w-full justify-between">
                  <span className="w-1/4">Name</span>
                  <input
                    type="text"
                    name="name"
                    value={section.name}
                    onChange={(e) =>
                      handleInputChange(
                        section.id,
                        e.target.name,
                        e.target.value
                      )
                    }
                    className="border-[1px] px-1 rounded w-1/4 border-black"
                  />
                  <span className="w-1/4">Value</span>
                  <input
                    type="text"
                    name="value"
                    value={section.value}
                    onChange={(e) =>
                      handleInputChange(
                        section.id,
                        e.target.name,
                        e.target.value
                      )
                    }
                    className="border-[1px] px-1 rounded w-1/4 border-black"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                onClick={() => removeSection(section.id)}
                className="text-blue-500 text-sm"
              >
                Delete
              </button>
            </div>
          </div>
        ))}

        <button
          onClick={addSection}
          className="flex items-center mt-4 text-blue-500"
        >
          <FaPlus className="mr-2" /> Add
        </button>
      </div>

      <div className="px-4 pb-3 text-gray-600">
        <div className="flex flex-row items-center my-4 justify-between">
          <label className="w-[30%] mr-2">Total Construction Cost</label>
          <div className="flex flex-row items-center justify-end w-[50%]">
            <input
              type="text"
              className="border-[1px] px-2 rounded w-[90%] border-gray-400 bg-gray-100"
              disabled={true}
              value={toggleData?.TotalConstructionCost ?? "--"}
            />
            <span className="w-[25%] text-right">$</span>
          </div>
        </div>
        <ApplyChanges
          updateCapData={updateCapData}
          capData={capData}
          currentTable={currentTable}
          isUpdateCapDataLoading={isUpdateCapDataLoading}
        />
      </div>
    </>
  );
};

export default AdditionalConstructionSection;
